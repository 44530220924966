import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import store from './redux/store.js';

import Home from './pages/Home/Home';
import Whatwedo from './pages/Talk/Whatwedo/Whatwedo';
import OurProjects from './pages/Talk/Ourprojects/Ourproject';
import Whoarewe from './pages/About/Whoweare/Whoweare';
import Whatwebelieve from './pages/About/Whatwebelive/Whatwebelieve';
import Lightschool from './pages/Light/Lightschool/Lightschool';
import Articles from './pages/Light/Lightblogs/Articlehome/ArticleHome';
import Carbonemission from './pages/Calculator/Carbonemission/Carbonemission';
import LightFinder from './pages/LightFinder/LightFinder';
import LightFinder1 from './pages/LightFinder/LightFinder1';
import LightFinder2 from './pages/LightFinder/LightFinder2';
import LightFinder3 from './pages/LightFinder/LightFinder3';
import LightFinder4 from './pages/LightFinder/LightFinder4';
import LightFinder5 from './pages/LightFinder/LightFinder5';
import LightFinder6 from './pages/LightFinder/LightFinder6';
import LightFinder7 from './pages/LightFinder/LightFinder7';
import LightFinder8 from './pages/LightFinder/LightFinder8';
import Pollinators from './pages/Light/Lightblogs/Articlepages/Pollinators';
import Immersive from './pages/Light/Lightblogs/Articlepages/Immersive';
import Festivals from './pages/Light/Lightblogs/Articlepages/Festivals';
import LightAI from './pages/Light/Lightblogs/Articlepages/LightAI';
import Victoria from './pages/Light/Lightblogs/Articlepages/Victorian';
import Ecosystem from './pages/Light/Lightblogs/Articlepages/Ecosystem';
import Tourism from './pages/Light/Lightblogs/Articlepages/Tourism';
import Human from './pages/Light/Lightblogs/Articlepages/Human';
import Lighting from './pages/Light/Lightblogs/Articlepages/Lighting';
import Dancing from './pages/Light/Lightblogs/Articlepages/Dancing';
import CCGetres from './pages/Calculator/Dashboard/CCGetres';

import './App.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: 'whatwedo',
    element: <Whatwedo />,
  },
  {
    path: 'ourprojects',
    element: <OurProjects />,
  },
  {
    path: 'whoarewe',
    element: <Whoarewe />,
  },
  {
    path: 'whatwebelieve',
    element: <Whatwebelieve />,
  },
  {
    path: 'lightschool',
    element: <Lightschool />,
  },
  {
    path: 'blog',
    element: <Navigate to="https://kaash.eu/blog" replace={true} />, // Redirect to external link
  },
  {
    path: 'carbonemission',
    element: <Carbonemission />,
  },
  {
    path: 'lightfinder',
    element: <LightFinder />,
  },
  {
    path: 'lightfinder1',
    element: <LightFinder1 />,
  },
  {
    path: 'lightfinder2',
    element: <LightFinder2 />,
  },
  {
    path: 'lightfinder3',
    element: <LightFinder3 />,
  },
  {
    path: 'lightfinder4',
    element: <LightFinder4 />,
  },
  {
    path: 'lightfinder5',
    element: <LightFinder5 />,
  },
  {
    path: 'lightfinder6',
    element: <LightFinder6 />,
  },
  {
    path: 'lightfinder7',
    element: <LightFinder7 />,
  },
  {
    path: 'lightfinder8',
    element: <LightFinder8 />,
  },
  {
    path: 'articles',
    element: <Articles />,
  },
  {
    path: 'pollinators',
    element: <Pollinators />,
  },
  {
    path: 'immersive',
    element: <Immersive />,
  },
  {
    path: 'festivals',
    element: <Festivals />,
  },
  {
    path: 'lightai',
    element: <LightAI />,
  },
  {
    path: 'victorian',
    element: <Victoria />,
  },
  {
    path: 'ecosystem',
    element: <Ecosystem />,
  },
  {
    path: 'tourism',
    element: <Tourism />,
  },
  {
    path: 'human',
    element: <Human />,
  },
  {
    path: 'lighting',
    element: <Lighting />,
  },
  {
    path: 'dancing',
    element: <Dancing />,
  },
  {
    path: 'CCGetres',
    element: <CCGetres />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
);
